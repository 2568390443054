import { useBodyScrollLock } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, spacing } from '../tokens'
import { composeRefs } from '../utils'
import { DialogContext, DialogContextValue } from './DialogContext'
import { FocusTrap } from '../FocusTrap'
import { PropsFromHook } from './types'

function styles(): CSSObject {
  return {
    backgroundColor: 'transparent',
    borderWidth: 0,
    padding: 0,

    '&::backdrop': {
      backdropFilter: 'blur(8px)',
      backgroundColor: colors.overlay,
      padding: spacing.modal.overlay,
    },
  }
}

const StyledDialog = styled.dialog(styles)

type BaseProps = Omit<
  React.DialogHTMLAttributes<HTMLDialogElement>,
  'aria-label' | 'aria-labelledby'
> &
  PropsFromHook & {
    /**
     * Disables the the underlying <FocusTrap> component.
     */
    disableFocusTrap?: boolean
    /**
     * Disables auto focus functionality provided by the underlying <FocusTrap>
     * component.
     */
    disableAutoFocus?: boolean
  }

type Props =
  | (BaseProps & { 'aria-label': string; 'aria-labelledby'?: string })
  | (BaseProps & { 'aria-label'?: string; 'aria-labelledby': string })

/**
 * A dialog is a window overlaid on either the primary window or another
 * dialog window. They are most often used to prompt the user to enter or
 * respond to information. Dialogs contain their tab sequence. That is,
 * `Tab` and `Shift + Tab` do not move focus outside the dialog.
 *
 * Because of the imperative nature of the HTML `<dialog>` element API,
 * you'll have to use the `useDialog()` hook to access the API via the `ref`
 * property returned by the hook.
 *
 * Remember to provide an accessible name for the dialog, which can be done with
 * the `aria-label` or `aria-labelledby` attribute(s).
 *
 * @example
 * function Modal() {
 *   const { ref, triggerProps, ...dialog } = useDialog({ asModal: true })
 *
 *   return (
 *     <>
 *      <Button
 *        {...triggerProps}
 *        onClick={() => {
 *          dialog.open()
 *        }}
 *      >
 *        Open
 *      </Button>
 *      <Dialog {...dialog} aria-labelledby="title-123" ref={ref}>
 *        <Card column>
 *          <Text id="title-123">This is a modal dialog</Text>
 *          <Spacer />
 *          <Flex fullWidth justifyContent="space-between">
 *            <Button>Some Button</Button>
 *            <Spacer inline />
 *            <Button
 *              onClick={() => {
 *                dialog.close()
 *              }}
 *            >
 *              Close
 *            </Button>
 *          </Flex>
 *        </Card>
 *      </Dialog>
 *     </>
 *   )
 * }
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/}
 * @see {@link https://w3c.github.io/aria/#dialog}
 */
const Dialog = React.forwardRef<HTMLDialogElement, Props>(function Dialog(
  {
    children,
    close,
    disableAutoFocus,
    id,
    isModal,
    isOpen,
    show,
    ...props
  }: Props,
  ref,
) {
  const contextValue = React.useMemo<DialogContextValue>(
    () => ({ close, id, isModal, isOpen, show }),
    [close, id, isModal, isOpen, show],
  )

  useBodyScrollLock({ isActive: isModal && isOpen })

  return (
    <DialogContext.Provider value={contextValue}>
      <FocusTrap autoFocus={!disableAutoFocus} disabled={!isOpen}>
        {(focusTrapRef, focusTrapProps) => (
          <StyledDialog
            {...focusTrapProps}
            data-gourmet-dialog=""
            id={id}
            ref={composeRefs<HTMLDialogElement>([ref, focusTrapRef])}
            {...props}
          >
            {children}
          </StyledDialog>
        )}
      </FocusTrap>
    </DialogContext.Provider>
  )
})

Dialog.displayName = 'Dialog'
Dialog.defaultProps = {}

export { Dialog }
export type { Props as DialogProps }
