import React from 'react'

import { UserContextValue } from './types'
import { useCurrentUser as useCurrentUserInternal } from './hook'

const UserContext = React.createContext<UserContextValue | undefined>(undefined)

UserContext.displayName = 'UserContext'

export { UserContext }

/**
 * Returns the current `value` for the parent `<UserContext>`, if any.
 */
export function useCurrentUser(): UserContextValue {
  const context = React.useContext(UserContext)

  if (context === undefined) {
    throw new Error(
      'The `useCurrentUser` hook must be used within `<UserProvider>`',
    )
  }

  return context
}

/**
 * A React provider component that exposes the return from the
 * `useCurrentUser` hook to all children components.
 *
 * @example
 * ```
 * function AppComponent() {
 *   return (
 *     <UserProvider>
 *       <ChildComponent />
 *     </UserProvider>
 *   )
 * }
 *
 * function ChildComponent() {
 *   const user = useCurrentUser()
 *
 *   return user.state.isLoggedIn ? (
 *     <UserDashboardPage />
 *   ) : (
 *     <LoginPage />
 *   )
 * }
 * ```
 */
export function UserProvider(
  props: React.PropsWithChildren<unknown>,
): JSX.Element {
  const value = useCurrentUserInternal()

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}
