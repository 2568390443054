import React from 'react'

import {
  getSessionStorageRecord,
  updateSessionStorageRecord,
} from '@vori/dashboard-utils'

type GlobalHTTPHeaders = {
  'selected-store-sql-id': string
}

/**
 * Provides a tuple consisting of a getter and setter for dealing with global
 * HTTP headers that are persister through SessionStorage.
 */
export function useGlobalHTTPHeaders(): [
  GlobalHTTPHeaders | null,
  (headers: GlobalHTTPHeaders) => void,
] {
  const [headers, setValue] = React.useState<GlobalHTTPHeaders | null>(
    getSessionStorageRecord<GlobalHTTPHeaders>('http-headers'),
  )

  const setHeaders = React.useCallback((headers: GlobalHTTPHeaders) => {
    updateSessionStorageRecord('http-headers', headers)
    setValue(getSessionStorageRecord<GlobalHTTPHeaders>('http-headers'))
  }, [])

  return React.useMemo(() => [headers, setHeaders], [headers, setHeaders])
}
