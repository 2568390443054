import React from 'react'
import styled from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'

import { Button } from '../../ButtonNext'
import { Flex } from '../../FlexNext'

import { colors } from '../../tokens'
import { MultiSelectFooterActionsProps } from './utils/types'

const Container = styled(Flex)({
  borderTopColor: colors.multiSelect.footerActions.borderColor,
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  marginBottom: `-${foundations.space['space.100']}`,
  marginLeft: `-${foundations.space['space.125']}`,
  marginRight: `-${foundations.space['space.125']}`,
  padding: `${foundations.space['space.075']} ${foundations.space['space.100']}`,
  width: `calc(100% + (${foundations.space['space.125']} * 2))`,
})

function MultiSelectFooterActions({
  clearBtnProps,
  doneBtnProps,
  withSingleClearAction,
}: MultiSelectFooterActionsProps): JSX.Element {
  return (
    <Container centerY fullWidth justifyContent="space-between" gap="space.075">
      {withSingleClearAction ? (
        <Button
          {...clearBtnProps}
          asClickable
          sizing="small"
          fullWidth
          variant="primary"
          withLeftAlignedText
        >
          Clear Selection
        </Button>
      ) : (
        <>
          <Button {...clearBtnProps} sizing="small" fullWidth variant="default">
            Clear
          </Button>
          <Button
            {...doneBtnProps}
            sizing="small"
            fullWidth
            onClick={(event) => {
              const triggerEl = document.querySelector(
                '[data-reach-listbox-button][aria-expanded="true"]',
              )

              if (triggerEl != null) {
                triggerEl.dispatchEvent(
                  new Event('mousedown', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                  }),
                )
              }

              if (doneBtnProps?.onClick != null) {
                doneBtnProps.onClick(event)
              }
            }}
            variant="secondary"
          >
            Done
          </Button>
        </>
      )}
    </Container>
  )
}

export default MultiSelectFooterActions
