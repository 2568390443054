import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/browser'
import React from 'react'
import posthog from 'posthog-js'

import { Analytics } from '@vori/dashboard-utils'

import {
  ENV_PROD,
  ENV_PUBLIC_POSTHOG_KEY,
  ENV_VERSION,
} from '@vori/dashboard-env'

import { User, UserMetadata } from './types'

export type AnalyticsHookOptions = {
  data: User
  isLoggedIn: boolean
  userMetadata: UserMetadata
}

export function useAnalytics({
  data,
  isLoggedIn,
  userMetadata,
}: AnalyticsHookOptions): void {
  React.useEffect(() => {
    if (!ENV_PROD || !isLoggedIn || !data) {
      return
    }

    const email = data.email || ''
    const userID = data.id
    const username = data.displayName || ''
    const userType = data.userType || ''

    const identity: Sentry.User = {
      companyID: data.companyID,
      companyName: data.companyName,
      email,
      id: userID,
      username,
    }

    Sentry.configureScope((scope) => {
      scope.setUser(identity)
    })

    FullStory.setUserVars(identity)

    // Segment
    if (userID) {
      const analytics = window.analytics

      if (analytics && typeof analytics.identify === 'function') {
        analytics.identify(userID, { email, name: username })
      }

      Analytics.identify({
        email,
        storeID: userMetadata.selectedStoreID || '',
        storeName: userMetadata.selectedStoreName || '',
        userID,
        username,
        userType,
      })
    }

    // Posthog
    if (ENV_PROD) {
      posthog.init(ENV_PUBLIC_POSTHOG_KEY, {
        api_host: 'https://app.posthog.com',
        enable_recording_console_log: true,
        loaded: function (posthog) {
          posthog.identify(
            userID,
            {
              email: data.email || '',
              name: data.displayName || '',
              company_name: data.companyName,
              company_id: data.companyID,
              user_id: data.id,
              app_version: ENV_VERSION,
            },
            {},
          )
        },
      })

      posthog.identify(
        userID,
        {
          email: data.email || '',
          name: data.displayName || '',
          company_name: data.companyName,
          company_id: data.companyID,
          user_id: data.id,
          app_version: ENV_VERSION,
        },
        {},
      )
    }
  }, [
    data,
    isLoggedIn,
    userMetadata.selectedStoreID,
    userMetadata.selectedStoreName,
  ])
}
