import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
  FlexNext as Flex,
  Heading,
  Loader,
  SpacerNext as Spacer,
  TextNext as Text,
} from '@vori/gourmet-components'

import { ColDef } from 'ag-grid-community'
import { DataGridActions, DataGridActionsProps } from './DataGridActions'
import { DataGridContainer, DataGridContainerProps } from './DataGridContainer'
import { DataGridDivider } from './DataGridDivider'
import { DataGridFilters, DataGridFiltersProps } from './DataGridFilters'
import { DataGridHookReturn } from './hooks'
import { DataGridLoadingCellRenderer } from './renderers'
import { DataGridColumnDefinition, DataGridRowData } from './types'

const DataGridHeaders = styled(Flex)({
  position: 'relative',
  zIndex: 10,
})

const DataGridInnerContainer = styled(Flex)({
  position: 'relative',

  '& .ag-root-wrapper': {
    position: 'relative',
    zIndex: 1,
  },
})

const DataGridOverlay = styled(Flex)({
  backgroundColor: '#FFFFFF',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 9,
})

const DataGridDisabledOverlay = styled(Flex)({
  backgroundColor: 'rgba(52, 64, 84, 0.2)',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 10,
})

type Props<TData extends DataGridRowData> = AgGridReactProps<TData> & {
  actionsHeaderProps?: DataGridActionsProps
  /**
   * This will be whatever is returned from any of the initialization hooks:
   * - `useDataGrid`
   * - `useServerSideDataGrid`
   */
  dataGrid: DataGridHookReturn<TData>
  disabled?: boolean
  emptyStateMessage?: React.ReactNode
  errorStateMessage?: React.ReactNode
  filtersHeaderProps?: DataGridFiltersProps
  flexContainerProps?: DataGridContainerProps
  noContainer?: boolean
  showEmptyState?: boolean
  showErrorState?: boolean
  showLoadingState?: boolean
  withActionsHeader?: boolean
  withFiltersHeader?: boolean
  /**
   * Boolean flag to determine if the rows are clickable, in which case the
   * rows will have a different background color on hover.
   */
  withClickableRow?: boolean
  columnDefs: DataGridColumnDefinition<TData>[] | ColDef<TData>[]
  initialState?: AgGridReactProps<TData>['initialState']
}

function DataGrid<TData extends DataGridRowData>({
  actionsHeaderProps,
  disabled,
  emptyStateMessage = 'No data currently available to display.',
  errorStateMessage = 'There was an error while loading the data.',
  filtersHeaderProps,
  flexContainerProps,
  noContainer,
  showEmptyState,
  showErrorState,
  showLoadingState,
  withActionsHeader,
  withFiltersHeader,
  withClickableRow,
  ...props
}: Props<TData>): JSX.Element {
  const defaultColDef = useMemo(
    () => ({
      minWidth: 100,
      sortable: false,
      ...props.defaultColDef,
    }),
    [props.defaultColDef],
  )

  const content = (
    <>
      {(withFiltersHeader || withActionsHeader) && (
        <DataGridHeaders direction="column" centerY fullWidth>
          {withFiltersHeader && (
            <>
              <DataGridFilters {...filtersHeaderProps} />
              <DataGridDivider />
            </>
          )}

          {withActionsHeader && (
            <>
              <DataGridActions {...actionsHeaderProps} />
              <DataGridDivider />
            </>
          )}
        </DataGridHeaders>
      )}

      <DataGridInnerContainer
        {...(withClickableRow && { 'data-clickable-rows': '' })}
        flex={1}
        fullHeight
        fullWidth
      >
        {disabled && <DataGridDisabledOverlay />}

        {showEmptyState && (
          <DataGridOverlay center flex={1}>
            {typeof emptyStateMessage === 'string' ? (
              <Heading size="heading-xs" variant="secondary">
                {emptyStateMessage}
              </Heading>
            ) : (
              emptyStateMessage
            )}
          </DataGridOverlay>
        )}

        {showErrorState && (
          <DataGridOverlay center flex={1}>
            {typeof errorStateMessage === 'string' ? (
              <Heading size="heading-xs" variant="negative">
                {errorStateMessage}
              </Heading>
            ) : (
              errorStateMessage
            )}
          </DataGridOverlay>
        )}

        {showLoadingState && (
          <DataGridOverlay direction="column" center flex={1}>
            <Loader size="large" />
            <Spacer size="space.025" />
            <Text align="center" variant="secondary">
              Loading Data
            </Text>
          </DataGridOverlay>
        )}

        <AgGridReact<TData>
          rowHeight={60}
          {...props}
          defaultColDef={defaultColDef}
          reactiveCustomComponents
          containerStyle={{
            height: '100%',
            width: '100%',
            ...props.containerStyle,
          }}
          loadingCellRenderer={DataGridLoadingCellRenderer}
          rowStyle={{ width: '100%', ...props.rowStyle }}
          suppressAggFuncInHeader
          suppressMakeColumnVisibleAfterUnGroup
        />
      </DataGridInnerContainer>
    </>
  )

  return noContainer ? (
    content
  ) : (
    <DataGridContainer {...flexContainerProps}>{content}</DataGridContainer>
  )
}

export { DataGrid }
export type { Props as DataGridProps }
