/**
 * @todo - When we are finally able to generate separate deployments based on
 * user roles, we can make a webpack plugin that can remove these components
 * based on a user role, thus reducing the bundle size for specific dashboards.
 */

import React from 'react'

import { UserType } from '@vori/gql-dashboard'
import { useCurrentUser } from '@vori/dashboard-hooks/useCurrentUser'

type Props = React.PropsWithChildren<{
  role: UserType | Array<UserType>
}>

function UserRoleGate({ children, role }: Props): JSX.Element | null {
  const user = useCurrentUser()
  const roles = Array.isArray(role) ? role : [role]

  return roles.some((userType) => user.data.userType === userType) ? (
    <>{children}</>
  ) : null
}

function RetailUserGate({ children }: Omit<Props, 'role'>): JSX.Element | null {
  const user = useCurrentUser()
  return [
    UserType.StoreAdmin,
    UserType.Buyer,
    UserType.StoreReceiver,
    UserType.StoreAccountsPayable,
  ].some((role) => role === user.data.userType) ? (
    <>{children}</>
  ) : null
}

function VendorUserGate({ children }: Omit<Props, 'role'>): JSX.Element | null {
  const user = useCurrentUser()
  return user.data.userType === UserType.VendorAdmin ? <>{children}</> : null
}

function DevUserGate({ children }: Omit<Props, 'role'>): JSX.Element | null {
  const user = useCurrentUser()
  return user.data.userType === UserType.VoriAdmin ? <>{children}</> : null
}

export { DevUserGate, RetailUserGate, UserRoleGate, VendorUserGate }
