import {
  useRestAPIGet,
  RestAPIHookReturn,
} from '@vori/dashboard-hooks/useRestAPI'

import { UserPermissions } from './types'

export type PermissionsHookOptions = {
  fetchOptions?: RequestInit
  skip?: boolean
}

export function usePermissions({
  fetchOptions,
  skip,
}: PermissionsHookOptions): RestAPIHookReturn<UserPermissions> {
  return useRestAPIGet<UserPermissions>({
    endpoint: '/api/permissions',
    fetchOptions,
    skip,
  })
}
