import type { User } from 'firebase/auth'
import React from 'react'

import { useGlobalHTTPHeaders } from '@vori/dashboard-hooks/useGlobalHTTPHeaders'

import { ReducerAction, UserUtils } from './types'

export type UtilsHookOptions = {
  firebaseUser?: User | null
  dispatch: React.Dispatch<ReducerAction>
}

export function useUtils({
  dispatch,
  firebaseUser,
}: UtilsHookOptions): UserUtils {
  const [, setHTTPHeaders] = useGlobalHTTPHeaders()

  return React.useMemo<UserUtils>(
    () => ({
      getAuthToken: () => firebaseUser?.getIdToken() || null,
      reset: () => {
        dispatch({ type: 'RESET' })
      },
      selectStore: (selectedStoreID, selectedStoreName) => {
        setHTTPHeaders({ 'selected-store-sql-id': selectedStoreID || '' })
        dispatch({
          type: 'SELECT_STORE',
          payload: { selectedStoreName, selectedStoreID },
        })
      },
    }),
    [dispatch, firebaseUser, setHTTPHeaders],
  )
}
