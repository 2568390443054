import { useApolloClient } from '@apollo/client'
import posthog from 'posthog-js'
import React from 'react'
import { Redirect } from 'react-router'

import { useCurrentUser } from '@vori/dashboard-hooks/useCurrentUser'
import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { getFirebaseApp } from '@vori/dashboard-utils'
import { FlexNext as Flex, Loader } from '@vori/gourmet-components'
import { getAuth } from 'firebase/auth'

function LogoutPage(): JSX.Element {
  const apolloClient = useApolloClient()
  const app = getFirebaseApp()
  const user = useCurrentUser()

  if (!app) {
    return <Redirect to={authRoutes.login.root.path} />
  }

  getAuth(app)
    .signOut()
    .then(() => {
      apolloClient.cache.reset()
      sessionStorage.clear()
      posthog.reset()
      user.utils.reset()
      /**
       * @todo Figure out how to fully clear Apollo and Firebase local caches
       * to avoid refreshing the browser
       */
      window.location.reload()
    })

  return (
    <Flex center direction="column" flex={1}>
      <Loader size="large" />
    </Flex>
  )
}

export { LogoutPage }
