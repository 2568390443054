import { ReducerState, UserMetadata, UserState } from './types'

export function hasError(nextState: ReducerState): boolean {
  return Object.values(nextState.errors).some((error) => error !== null)
}

export function calculateState(
  nextState: ReducerState,
  nextUserState: Partial<UserState>,
): UserState {
  const { isLoading, isLoggedIn, isLoggedOut, ...calculatedState }: UserState =
    {
      ...nextState.state,
      ...nextUserState,
    }

  const nextHasError = hasError(nextState)
  const nextIsLoading = Object.values(calculatedState).some(Boolean)

  const nextIsLoggedIn =
    !nextIsLoading &&
    !nextHasError &&
    !isLoggedOut &&
    Boolean(nextState.data.id)

  return {
    ...calculatedState,
    hasError: nextHasError,
    isLoading: (nextIsLoading || !nextIsLoggedIn) && !isLoggedOut,
    isLoggedIn: nextIsLoggedIn,
    isLoggedOut,
  }
}

export function calculateMetadata(
  state: ReducerState,
  metadata: Partial<UserMetadata>,
): ReducerState {
  const nextState = {
    ...state,
    lastUpdateTimestamp: Date.now(),
    metadata: { ...state.metadata, ...metadata },
  }

  return {
    ...nextState,
    state: calculateState(nextState, {}),
  }
}
