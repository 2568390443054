import { NavLink, NavLinkProps } from 'react-router-dom'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import {
  buttonDefaultProps,
  ButtonCoreProps,
  buttonStyles,
} from '../ButtonNext'

function styles(): CSSObject {
  return {
    ...buttonStyles(),
    textDecoration: 'none',

    '&:hover, &:active, &:focused, &:visited': {
      textDecoration: 'none',
    },
  }
}

const StyledNavButtonInternal = styled(NavLink)(styles)
const StyledNavButtonExternal = styled.a(styles)

type CoreProps = ButtonCoreProps

type Props = CoreProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'aria-label' | 'aria-labelledby'
  > &
  (
    | { href: string; to?: undefined }
    | ({ href?: undefined } & Omit<
        NavLinkProps,
        'href' | 'aria-label' | 'aria-labelledby'
      >)
  )

const defaultProps: Partial<CoreProps> = buttonDefaultProps

/**
 * A `<NavButton>` provides an interactive reference to a resource. The
 * target resource can be either external or local, i.e., either outside using
 * `href` or within the current application using the `to` prop.
 *
 * @example
 * <NavButton to="/login" leftIcon={<AtSignIcon />}>Login</Link>
 *
 * @see {@link https://w3c.github.io/aria/#link}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link}
 */
const NavButton = React.forwardRef<HTMLAnchorElement, Props>(function NavButton(
  {
    asClickable,
    asIconButton,
    children,
    fullWidth,
    leftIcon,
    noFocusRing,
    noPadding,
    rightIcon,
    sizing = 'default',
    variant = 'default',
    ...props
  }: Props,
  ref: React.Ref<HTMLAnchorElement>,
): JSX.Element {
  const commonProps = {
    'data-gourmet-nav-button': '',
    ...(!asIconButton && leftIcon && { 'data-left-icon': '' }),
    ...(!asIconButton && rightIcon && { 'data-right-icon': '' }),
    ...(asClickable && { 'data-clickable': '' }),
    ...(asIconButton && { 'data-icon-button': '' }),
    ...(fullWidth && { 'data-full-width': '' }),
    ...(noFocusRing && { 'data-no-focus-ring': '' }),
    ...(noPadding && { 'data-no-padding': '' }),
    ...(sizing && { 'data-sizing': sizing }),
    ...(variant && { 'data-variant': variant }),
  }

  if (props.href != null) {
    return (
      <StyledNavButtonExternal {...props} {...commonProps} ref={ref}>
        {leftIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {leftIcon}
          </span>
        )}
        {!asIconButton && (
          <>
            {children}
            {rightIcon && (
              <span aria-hidden="true" data-gourmet-button-icon="">
                {rightIcon}
              </span>
            )}
          </>
        )}
      </StyledNavButtonExternal>
    )
  } else {
    return (
      <StyledNavButtonInternal {...props} {...commonProps} ref={ref}>
        {leftIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {leftIcon}
          </span>
        )}
        {!asIconButton && (
          <>
            {children}
            {rightIcon && (
              <span aria-hidden="true" data-gourmet-button-icon="">
                {rightIcon}
              </span>
            )}
          </>
        )}
      </StyledNavButtonInternal>
    )
  }
})

NavButton.displayName = 'NavButton'
NavButton.defaultProps = defaultProps

export {
  NavButton,
  defaultProps as navButtonDefaultProps,
  styles as navButtonStyles,
}

export type { Props as NavButtonProps, CoreProps as NavButtonCoreProps }
