import React from 'react'

import {
  useDepartments,
  useItemModifiers,
  useStoreVendors,
  useStores,
  useTaxes,
  useVariableWeights,
} from '../../../pages/retail/hooks'
import { UserEntities } from './types'

export type EntitiesHookOptions = {
  skip?: boolean
}

export type EntitiesHookState = {
  data: UserEntities
  error: Error | null
  loading: boolean
}

export function useEntities(options: EntitiesHookOptions): EntitiesHookState {
  const storesResponse = useStores({ skip: options.skip })

  const departmentsResponse = useDepartments({
    skip: options.skip,
  })

  const taxesResponse = useTaxes({
    skip: options.skip,
  })

  const itemModifiersResponse = useItemModifiers({
    skip: options.skip || !storesResponse.stores.length,
    storeId: storesResponse.stores[0]?.serialID,
  })

  const variableWeights = useVariableWeights({
    skip: options.skip || !storesResponse.stores.length,
    storeId: storesResponse.stores[0]?.serialID,
  })

  const storeVendors = useStoreVendors({
    skip: options.skip || !storesResponse.stores.length,
    storeId: storesResponse.stores[0]?.serialID,
  })

  return React.useMemo(
    () => ({
      data: {
        departments: departmentsResponse.departments,
        stores: storesResponse.stores,
        taxes: taxesResponse.taxRates,
        itemModifiers: itemModifiersResponse.itemModifiers,
        variableWeights: variableWeights.variableWeights,
        storeVendors: storeVendors.storeVendors,
      },
      error:
        storesResponse.error ||
        departmentsResponse.error ||
        taxesResponse.error ||
        itemModifiersResponse.error ||
        variableWeights.error ||
        storeVendors.error ||
        null,
      loading:
        storesResponse.loading ||
        departmentsResponse.loading ||
        taxesResponse.loading ||
        itemModifiersResponse.loading ||
        variableWeights.loading ||
        storeVendors.loading ||
        false,
    }),
    [
      departmentsResponse.departments,
      departmentsResponse.error,
      departmentsResponse.loading,
      itemModifiersResponse.error,
      itemModifiersResponse.itemModifiers,
      itemModifiersResponse.loading,
      storeVendors.error,
      storeVendors.loading,
      storeVendors.storeVendors,
      storesResponse.error,
      storesResponse.loading,
      storesResponse.stores,
      taxesResponse.error,
      taxesResponse.loading,
      taxesResponse.taxRates,
      variableWeights.error,
      variableWeights.loading,
      variableWeights.variableWeights,
    ],
  )
}
