import { getFirebaseApp } from '@vori/dashboard-utils'
import {
  type User,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  getAuth,
} from 'firebase/auth'
import React from 'react'

export type FirebaseAuthHookState = {
  authToken: string | null
  data: User | null
  error: Error | null
  loading: boolean
}

export type FirebaseAuthHookOptions = {
  onAuthStateChanged?: (user: User | null) => void
  skip?: boolean
}

export function useFirebaseAuth({
  onAuthStateChanged,
  skip,
}: FirebaseAuthHookOptions): FirebaseAuthHookState {
  const [state, setState] = React.useState<FirebaseAuthHookState>({
    authToken: null,
    data: null,
    error: null,
    loading: true,
  })

  const firebaseApp = React.useMemo(() => getFirebaseApp(), [])

  React.useEffect(() => {
    if (!firebaseApp || skip) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }))

      return
    }

    const auth = getAuth(firebaseApp)
    const unsubscribeFromAuthListener = firebaseOnAuthStateChanged(
      auth,
      async (user) => {
        if (user && !state.authToken) {
          const authToken = await user.getIdToken()
          setState({ authToken, data: user, error: null, loading: false })
          onAuthStateChanged?.(user)
        } else if (!user) {
          setState({ authToken: null, data: null, error: null, loading: false })
          onAuthStateChanged?.(user)
        }
      },
      (error) => {
        if (error) {
          setState({
            authToken: null,
            data: null,
            error: new Error(error.message),
            loading: false,
          })
        }
      },
    )

    return () => {
      unsubscribeFromAuthListener()
    }
  }, [firebaseApp, onAuthStateChanged, skip, state.authToken])

  return state
}
