import { History } from 'history'
import { Integrations } from '@sentry/tracing'
import { useEffect } from 'react'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'

import {
  Analytics,
  initIntercom,
  shutdownIntercom,
} from '@vori/dashboard-utils'

import {
  ENV_FIREBASE_PROJECT_ID,
  ENV_FULLSTORY_ORGANIZATION_ID,
  ENV_PROD,
  ENV_SENTRY_DSN,
  ENV_VERSION,
} from '@vori/dashboard-env'

import { useCurrentUser } from './useCurrentUser'

let isIntercomInitialized = false

function useIntegrations(history: History): void {
  const user = useCurrentUser()

  useEffect(() => {
    if (ENV_PROD) {
      Sentry.init({
        dsn: ENV_SENTRY_DSN,
        environment: (ENV_FIREBASE_PROJECT_ID || '').includes('dev')
          ? 'development'
          : 'production',
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
        ],
        tracesSampleRate: 0.15,
        release: ENV_VERSION,
      })

      FullStory.init(
        {
          orgId: ENV_FULLSTORY_ORGANIZATION_ID,
        },
        ({ sessionUrl }) =>
          console.debug(`FullStory Session URL: ${sessionUrl}`),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Analytics.page()
  }, [history.location])

  useEffect(() => {
    const email = user.data.email
    const displayName = user.data.displayName
    const userID = user.data.id
    const userType = user.data.userType

    if (ENV_PROD) {
      if (!isIntercomInitialized && user.state.isLoggedIn) {
        isIntercomInitialized = true

        initIntercom({
          email: email || undefined,
          name: displayName || undefined,
          user_id: userID || undefined,
          /**
           * Don't show the Intercom bubble unless users actively request
           * support otherwise, the bubble might obscure some UI elements,
           * especially on mobile
           */
          hide_default_launcher: true,
          job_title: userType || undefined,
        })
      } else if (isIntercomInitialized) {
        shutdownIntercom()
        isIntercomInitialized = false
      }
    }
  }, [
    user.state.isLoggedIn,
    user.data.email,
    user.data.displayName,
    user.data.id,
    user.data.userType,
  ])
}

export { useIntegrations }
