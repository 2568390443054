import { getTimeZone } from '@vori/dashboard-utils'
import { AppGetCurrentUserQuery, UserType } from '@vori/gql-dashboard'

import { ReducerAction, ReducerState, UserPermissions } from './types'
import { calculateMetadata, calculateState } from './utils'

export function getInitialState(): ReducerState {
  const noop = () => void 0

  return {
    data: {
      canSeeFeatures: { enabledFeatures: [] },
      companyID: '',
      companyImageURL: '',
      companyName: '',
      displayName: '',
      email: '',
      id: '',
      isBuyer: false,
      isDepartmentManager: false,
      isRetailUser: false,
      isSalesRep: false,
      isStoreAdmin: false,
      isStoreAP: false,
      isVendorAdmin: false,
      isVendorUser: false,
      isVoriAdmin: false,
      retailUser: null,
      userSqlID: '',
      userType: null,
      vendorUser: null,
    },
    entities: {
      departments: [],
      stores: [],
      taxes: [],
      itemModifiers: [],
      variableWeights: [],
      storeVendors: [],
    },
    errors: {
      authenticationError: null,
      entitiesError: null,
      permissionsError: null,
      userConfigError: null,
    },
    lastUpdateTimestamp: Date.now(),
    metadata: {
      selectedStoreID: null,
      selectedStoreName: null,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    permissions: {} as UserPermissions,
    state: {
      hasError: false,
      isAuthenticating: false,
      isLoading: true,
      isLoadingEntities: false,
      isLoadingPermissions: false,
      isLoadingUserConfig: false,
      isLoggedIn: false,
      isLoggedOut: false,
    },
    utils: {
      getAuthToken: noop,
      selectStore: noop,
    } as unknown as ReducerState['utils'],
  }
}

export function reducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case 'SET_AUTHENTICATION': {
      const nextState = {
        ...state,
        errors: {
          ...state.errors,
          authenticationError: action.payload.error || null,
        },
        lastUpdateTimestamp: Date.now(),
      }

      return {
        ...nextState,
        state: calculateState(nextState, {
          isAuthenticating: action.payload.loading,
          isLoggedOut: !action.payload.loading && !action.payload.authToken,
        }),
      }
    }

    case 'SET_DATA': {
      const userData = action.payload.data?.me?.user

      const nextState = {
        ...state,
        errors: {
          ...state.errors,
          userConfigError: action.payload.error || null,
        },
        data: {
          canSeeFeatures: userData?.canSeeFeatures || { enabledFeatures: [] },
          companyID:
            userData?.vendorUser?.vendor.id ||
            userData?.retailUser?.retailCompany.id ||
            '',
          companyImageURL: userData?.companyImageURL || '',
          companyName:
            userData?.vendorUser?.vendor.name ||
            userData?.retailUser?.retailCompany.name ||
            '',
          displayName: userData?.displayName || '',
          email: userData?.email || '',
          id: userData?.id || '',
          isBuyer: userData?.userType === UserType.Buyer,
          isDepartmentManager:
            userData?.userType === UserType.DepartmentManager,
          isRetailUser:
            Boolean(userData?.userType) &&
            [
              UserType.Buyer,
              UserType.DepartmentManager,
              UserType.StoreAccountsPayable,
              UserType.StoreAdmin,
              UserType.StoreReceiver,
            ].includes(userData?.userType as UserType),
          isSalesRep: userData?.userType === UserType.SalesRep,
          isStoreAdmin: userData?.userType === UserType.StoreAdmin,
          isStoreAP: userData?.userType === UserType.StoreAccountsPayable,
          isVendorAdmin: userData?.userType === UserType.VendorAdmin,
          isVendorUser:
            Boolean(userData?.userType) &&
            [UserType.SalesRep, UserType.VendorAdmin].includes(
              userData?.userType as UserType,
            ),
          isVoriAdmin: userData?.userType === UserType.VoriAdmin,
          retailUser:
            userData?.retailUser ||
            ({ retailCompany: {}, user: {} } as NonNullable<
              AppGetCurrentUserQuery['me']
            >['user']['retailUser']),
          userSqlID: userData?.userSqlID,
          userType: userData?.userType,
          vendorUser:
            userData?.vendorUser ||
            ({ vendor: {} } as NonNullable<
              AppGetCurrentUserQuery['me']
            >['user']['vendorUser']),
        },
        lastUpdateTimestamp: Date.now(),
      }

      return {
        ...nextState,
        state: calculateState(nextState, {
          isLoadingUserConfig: action.payload.loading,
        }),
      }
    }

    case 'SET_PERMISSIONS': {
      const nextState = {
        ...state,
        errors: {
          ...state.errors,
          permissionsError: action.payload.error || null,
        },
        lastUpdateTimestamp: Date.now(),
        permissions: action.payload.data || ({} as UserPermissions),
      }

      return {
        ...nextState,
        state: calculateState(nextState, {
          isLoadingPermissions: action.payload.loading,
        }),
      }
    }

    case 'SET_ENTITIES': {
      const nextState = {
        ...state,
        errors: {
          ...state.errors,
          entitiesError: action.payload.error || null,
        },
        entities: action.payload.data,
        lastUpdateTimestamp: Date.now(),
      }

      return {
        ...nextState,
        state: calculateState(nextState, {
          isLoadingEntities: action.payload.loading,
        }),
      }
    }

    case 'SET_METADATA': {
      return calculateMetadata(state, action.payload)
    }

    case 'SET_UTILS': {
      const nextState = {
        ...state,
        lastUpdateTimestamp: Date.now(),
        utils: { ...state.utils, ...action.payload },
      }

      return {
        ...nextState,
        state: calculateState(nextState, {}),
      }
    }

    case 'SELECT_STORE': {
      const { selectedStoreName, selectedStoreID } = action.payload
      const timeZone = getTimeZone()

      if (!selectedStoreID) {
        return calculateMetadata(state, {
          selectedStoreName: null,
          selectedStoreID: null,
          timeZone,
        })
      }

      const selectedStore = state.entities.stores.find(
        ({ serialID }) => selectedStoreID === serialID,
      )

      if (!selectedStore) {
        return calculateMetadata(state, {
          selectedStoreID,
          selectedStoreName,
          timeZone,
        })
      }

      return calculateMetadata(state, {
        selectedStoreID,
        selectedStoreName,
        timeZone: selectedStore.metadata?.local_iana_timezone || timeZone,
      })
    }

    case 'RESET': {
      return getInitialState()
    }

    default: {
      return state
    }
  }
}
