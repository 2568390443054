import { useStableCallback } from '@vori/react-hooks'
import React from 'react'

/**
 * Executes the given `callback` when clicking outside of the `elements` being
 * tracked.
 */
function useOnClickOutside(
  /**
   * An array of HTML elements that will be used to determine if the user is
   * clicking outside of them.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element}
   */
  elements: Array<HTMLElement | null>,
  /**
   * A callback function to be executed when the user has clicked outside of
   * the tracked `elements`
   */
  callback: (event: MouseEvent | TouchEvent) => void,
  /**
   * An optional boolean flag to conditionally executed the `callback`.
   */
  disable?: boolean,
): void {
  const stableCallback = useStableCallback(callback)

  const eventListener = React.useCallback<
    (event: MouseEvent | TouchEvent) => void
  >(
    (event) => {
      if (disable) {
        return
      }

      if (elements.includes(event.target as HTMLElement | null)) {
        return
      }

      if (
        elements.some(
          (element) =>
            element &&
            (element === event.target ||
              element.contains(event.target as Node)),
        )
      ) {
        return
      }

      stableCallback(event)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disable, stableCallback, elements.toString()],
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', eventListener)
    document.addEventListener('touchstart', eventListener)

    return () => {
      document.removeEventListener('mousedown', eventListener)
      document.removeEventListener('touchstart', eventListener)
    }
  }, [eventListener])
}

export default useOnClickOutside
