import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import React from 'react'

import { ENV_PROD } from '@vori/dashboard-env'
import { useGlobalHTTPHeaders } from '@vori/dashboard-hooks/useGlobalHTTPHeaders'

import { getInitialState, reducer } from './reducer'
import { ReducerAction, ReducerState, UserContextValue } from './types'
import { useAnalytics } from './useAnalytics'
import { useEntities } from './useEntities'
import { useFirebaseAuth } from './useFirebaseAuth'
import { usePermissions } from './usePermissions'
import { useUserData } from './useUserData'
import { useUtils } from './useUtils'

export function useCurrentUser(): UserContextValue {
  const apolloClient = useApolloClient()
  const [httpHeaders] = useGlobalHTTPHeaders()

  const [state, dispatch] = React.useReducer<
    React.Reducer<ReducerState, ReducerAction>
  >(reducer, getInitialState())

  useAnalytics({
    data: state.data,
    isLoggedIn: state.state.isLoggedIn,
    userMetadata: state.metadata,
  })

  // Clears Apollo cache after Firebase auth state change
  const onAuthStateChanged = React.useCallback(() => {
    apolloClient.cache.reset()
  }, [apolloClient.cache])

  const firebaseAuth = useFirebaseAuth({ onAuthStateChanged })

  React.useEffect(() => {
    dispatch({ type: 'SET_AUTHENTICATION', payload: firebaseAuth })
  }, [firebaseAuth])

  const utils = useUtils({ dispatch, firebaseUser: firebaseAuth.data })

  React.useEffect(() => {
    dispatch({ type: 'SET_UTILS', payload: utils })
  }, [utils])

  const userData = useUserData({
    skip: state.state.isAuthenticating || !firebaseAuth.data,
  })

  React.useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: userData })
  }, [userData])

  const userPermissions = usePermissions({
    skip: state.state.isAuthenticating || !firebaseAuth.data,
  })

  React.useEffect(() => {
    dispatch({ type: 'SET_PERMISSIONS', payload: userPermissions })
  }, [userPermissions])

  const userEntities = useEntities({
    skip:
      state.state.isLoggedOut ||
      state.state.isAuthenticating ||
      !firebaseAuth.data ||
      !state.data.isRetailUser,
  })

  React.useEffect(() => {
    dispatch({ type: 'SET_ENTITIES', payload: userEntities })
  }, [userEntities])

  React.useEffect(() => {
    if (!state.metadata.selectedStoreID && state.entities.stores.length > 0) {
      utils.selectStore(
        httpHeaders?.['selected-store-sql-id'] ||
          state.entities.stores[0].serialID ||
          state.entities.stores[0].id ||
          null,
        state.entities.stores[0].name || null,
      )
    }
  }, [
    httpHeaders,
    state.entities.stores,
    state.metadata.selectedStoreID,
    utils,
  ])

  /**
   * Log errors, if any, to Sentry
   */
  React.useEffect(() => {
    if (!ENV_PROD || !state.state.hasError) {
      return
    }

    Object.values(state.errors)
      .filter(Boolean)
      .forEach((error) => {
        Sentry.captureException(error, {
          extra: state.metadata,
          user: {
            companyID: state.data.companyID,
            companyName: state.data.companyName,
            email: state.data.email || '',
            id: state.data.id,
            username: state.data.displayName || '',
          },
        })
      })
  }, [state])

  return state
}
