import { inputValueAsString } from '../utils'

type Value = NonNullable<React.InputHTMLAttributes<HTMLInputElement>['value']>

export function validateNumberRangeValue(
  value: Value,
  valueParser?: (value: Value) => number,
): [
  string | null,
  {
    min: React.InputHTMLAttributes<HTMLInputElement>
    max: React.InputHTMLAttributes<HTMLInputElement>
  },
] {
  const minInput = document.createElement('input')
  const maxInput = document.createElement('input')

  minInput.type = 'number'
  minInput.step = 'any'
  maxInput.type = 'number'
  maxInput.step = 'any'

  const valueAsString = inputValueAsString(value)
  const [minValue, maxValue] = valueAsString.split(',')

  minInput.valueAsNumber = valueParser
    ? valueParser(minValue)
    : Number.parseFloat(minValue)

  maxInput.valueAsNumber = valueParser
    ? valueParser(maxValue)
    : Number.parseFloat(maxValue)

  if (maxValue) {
    minInput.max = String(maxInput.valueAsNumber)
  }

  if (minValue) {
    maxInput.min = String(minInput.valueAsNumber)
  }

  return [
    minInput.validationMessage || maxInput.validationMessage || null,
    {
      min: {
        min: minInput.min,
        max: minInput.max,
        ...(minInput.validationMessage && { 'aria-invalid': 'true' }),
        ...(minInput.validationMessage && {
          'aria-errormessage': minInput.validationMessage,
        }),
      },
      max: {
        min: maxInput.min,
        max: maxInput.max,
        ...(!minInput.validationMessage &&
          maxInput.validationMessage && { 'aria-invalid': 'true' }),
        ...(!minInput.validationMessage &&
          maxInput.validationMessage && {
            'aria-errormessage': maxInput.validationMessage,
          }),
      },
    },
  ]
}
