import * as FullStory from '@fullstory/browser'

/**
 * Returns the URL for the current FullStory session, or null,
 * if the URL couldn't be fetched
 */
export function getFullStorySessionURL(): string | null {
  if (!FullStory.isInitialized()) {
    return null
  }

  const sessionURL = FullStory.getCurrentSessionURL(true)
  if (!sessionURL || sessionURL.indexOf('not ready') !== -1) {
    return null
  }

  return sessionURL
}
