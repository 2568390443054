import React from 'react'

import {
  AppSidebar as GourmetAppSidebar,
  AppSidebarHeader,
  AppSidebarUser,
} from '@vori/gourmet-components'

import {
  DevUserGate,
  RetailUserGate,
  VendorUserGate,
} from '@vori/dashboard-components'

import { useCurrentUser } from '@vori/dashboard-hooks/useCurrentUser'
import DefaultAvatarImage from '@vori/dashboard-assets/images/default_avatar.png'
import { RetailNavigation } from '@vori/dashboard-retail-components/RetailNavigation'

import { DevNavigation } from '../pages/dev/components'
import { VendorNavigation } from '../pages/vendor/components'

function AppSidebar(): JSX.Element | null {
  const user = useCurrentUser()

  if (!user.state.isLoggedIn) {
    return null
  }

  return (
    <GourmetAppSidebar>
      <AppSidebarHeader>
        <AppSidebarUser
          companyName={user.data.companyName}
          displayName={user.data.displayName || ''}
          imgURL={user.data.companyImageURL || DefaultAvatarImage}
        />
      </AppSidebarHeader>

      <RetailUserGate>
        <RetailNavigation />
      </RetailUserGate>

      <VendorUserGate>
        <VendorNavigation />
      </VendorUserGate>

      <DevUserGate>
        <DevNavigation />
      </DevUserGate>
    </GourmetAppSidebar>
  )
}

export { AppSidebar }
