import { getHTTPHeaders } from '@vori/dashboard-api/getHTTPHeaders'
import { ENV_GAE_REST_ENDPOINT } from '@vori/dashboard-env'

import { RestApiError } from './error'
import { withForwardSlash } from './utils'

export type ErrorResponse = { message: string }
export type DataOnlyResponse<TResponse> = { data: TResponse }

/**
 * Executes a `GET` request against our REST API.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API}
 */
export async function get<TResponse, TErrorDetails = unknown>(
  /**
   * The endpoint you want to execute the `GET` request against.
   */
  key: string | [string, RequestInit | undefined],
  /**
   * Options to be passed directly to the `fetch` API.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Request#properties}
   */
  options?: RequestInit,
): Promise<TResponse> {
  const endpoint = Array.isArray(key) ? key[0] : key
  const derivedOptions = options || (key[1] as RequestInit | undefined) || {}
  const headers = await getHTTPHeaders()

  const isExternalURL = ['http://', 'https://', 'ftp://'].some((protocol) =>
    endpoint.startsWith(protocol),
  )

  return fetch(
    isExternalURL
      ? endpoint
      : `${ENV_GAE_REST_ENDPOINT}${withForwardSlash(endpoint)}`,
    {
      ...derivedOptions,
      method: 'GET',
      headers: {
        ...(!isExternalURL && {
          ...headers,
          'Content-Type': 'application/json',
        }),
        ...derivedOptions?.headers,
      },
    },
  )
    .then(async (response) => {
      /* Note (emmad): fetch api will throw an error if calling
      response.json() when the response is empty. To avoid this we instead
      call JSON.parse on response.text().

      See: https://stackoverflow.com/a/37318584
      */
      const text = await response.text()
      const data: TResponse = text ? JSON.parse(text) : null

      if (!response.ok) {
        throw new RestApiError<TErrorDetails>(data)
      }

      return data
    })
    .catch((error) => {
      throw error instanceof RestApiError
        ? (error as RestApiError<TErrorDetails>)
        : new RestApiError<TErrorDetails>(error)
    })
}
