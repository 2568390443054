import { useAppGetCurrentUserQuery } from '@vori/gql-dashboard'
import { GATED_FEATURE_IDs } from '@vori/dashboard-constants'

export type UserDataHookOptions = {
  headers?: RequestInit
  skip?: boolean
}

export function useUserData({
  headers,
  skip,
}: UserDataHookOptions): ReturnType<typeof useAppGetCurrentUserQuery> {
  return useAppGetCurrentUserQuery({
    context: { headers },
    skip,
    variables: { gatedFeatures: GATED_FEATURE_IDs },
  })
}
