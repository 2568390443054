import React from 'react'
import useSWR, { SWRResponse, SWRConfiguration } from 'swr'

import { get } from '@vori/dashboard-api/get'

type RestAPIHookReturn<TData, TError = Error> = SWRResponse<TData, TError> & {
  loading: boolean
}

type RestAPIHookOptions<
  TData,
  TQuery extends string | Record<string, string> | string[][] = Record<
    string,
    string
  >,
  TError = Error,
> = {
  /**
   * Configuration to be passed directly to the `useSWR` hook.
   *
   * @see {@link https://swr.vercel.app/docs/options}
   */
  configuration?: SWRConfiguration<TData, TError>
  /**
   * The endpoint you want to execute the `GET` request against.
   */
  endpoint: string
  /**
   * Query parameters to be sent to the endpoint, if any.
   */
  query?: TQuery | URLSearchParams
  /**
   * Boolean flag used to conditionally prevent this request from being
   * executed.
   */
  skip?: boolean
}

type RestAPIGetHookOptions<
  TData,
  TQuery extends Record<string, string> = Record<string, string>,
  TError = Error,
> = RestAPIHookOptions<TData, TQuery, TError> & {
  /**
   * Options to be passed directly to the `fetch` API.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Request#properties}
   */
  fetchOptions?: RequestInit
  /**
   * Will format the data before returning it from the hook
   */
  formatter?: (
    data?: Record<string, unknown> | Array<Record<string, unknown>> | TData,
  ) => TData | undefined
}

function useRestAPIGet<
  TData,
  TQuery extends Record<string, string> = Record<string, string>,
  TError = Error,
>({
  configuration,
  endpoint,
  fetchOptions,
  formatter,
  query,
  skip,
}: RestAPIGetHookOptions<TData, TQuery, TError>): RestAPIHookReturn<
  TData,
  TError
> {
  const searchParams = React.useMemo(() => new URLSearchParams(query), [query])

  const key = React.useMemo(
    () => [`${endpoint}${query ? `?${searchParams}` : ''}`, fetchOptions],
    [endpoint, fetchOptions, query, searchParams],
  )

  const response = useSWR<TData, TError>(skip ? null : key, get, configuration)

  return React.useMemo(
    () => {
      return {
        ...response,
        data: formatter ? formatter(response.data) : response.data,
        error: response.error,
        loading: !skip && !response.error && !response.data,
      }
    },
    // The `mutate` function returned by `useSWR` doesn't seem to be memoized
    // which causes memory leaks when using the response of this hook as a
    // dependency for an effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatter, response.data, response.error, skip],
  )
}

export { useRestAPIGet }
export type { RestAPIGetHookOptions, RestAPIHookReturn, RestAPIHookOptions }
