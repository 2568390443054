import { ApolloError } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import React from 'react'

import {
  GqlTaxRate,
  StoreDepartment as OriginalStoreDepartment,
  Scalars,
  Vendor,
  useBackOfficeGetCurrentUserStoresQuery,
  useBackOfficeTaxManagementGetTaxRatesQuery,
  useRetailGetStoreDepartmentsQuery,
} from '@vori/gql-dashboard'

import { useRestAPIGet } from '@vori/dashboard-hooks/useRestAPI'
import { ItemModifierDto, VariableWeightDto } from '@vori/dashboard-rest'
import { StoreEntity } from '@vori/dashboard-types'

export type StoreDepartment = Pick<
  OriginalStoreDepartment,
  'id' | 'imageURL' | 'name' | 'deactivatedAt' | 'parentDepartment'
> & {
  storeID: string
  storeName: string
  parentDepartmentID?: string
}

export { useGetProductRangeProduct } from './useGetProductRangeProduct'
export { useUpdateNotificationTemplate } from './useUpdateNotificationTemplate'

export function useStores(
  queryOptions?: Parameters<typeof useBackOfficeGetCurrentUserStoresQuery>['0'],
): {
  error?: ApolloError
  loading: boolean
  stores: Array<StoreEntity>
} {
  const { data, error, loading } = useBackOfficeGetCurrentUserStoresQuery({
    ...queryOptions,
    fetchPolicy: 'cache-first',
  })

  const stores = React.useMemo<Array<StoreEntity>>(() => {
    if (data?.me?.user.retailUser?.retailCompany.banner?.id) {
      return data.me.user.retailUser.retailCompany.banner.stores.nodes.map(
        ({ id, name, serialID, ...metadata }) => ({
          id,
          metadata,
          name: name || 'N/A',
          serialID: serialID || id,
        }),
      )
    }

    if (data?.me?.user.retailUser?.retailCompany.store?.id) {
      const { id, name, serialID, ...metadata } =
        data.me.user.retailUser.retailCompany.store

      return [
        {
          id,
          metadata,
          name: name || 'N/A',
          serialID: serialID || id,
        },
      ]
    }

    return []
  }, [data])

  return { stores, loading, error }
}

export function useDepartments(
  queryOptions?: Parameters<typeof useBackOfficeGetCurrentUserStoresQuery>['0'],
): {
  error?: ApolloError
  loading: boolean
  departments: Array<StoreDepartment>
} {
  const { data: storeData } = useBackOfficeGetCurrentUserStoresQuery({
    ...queryOptions,
    fetchPolicy: 'cache-first',
  })

  const bannerID = storeData?.me?.user.retailUser?.retailCompany.banner?.id

  const { data, error, loading } = useRetailGetStoreDepartmentsQuery({
    variables: {
      bannerID: bannerID as Scalars['ID'],
    },
    skip: !bannerID || queryOptions?.skip,
    fetchPolicy: 'cache-first',
  })

  const departments = React.useMemo<Array<StoreDepartment>>(
    () =>
      sortBy(
        data?.storeDepartments
          .filter(({ deactivatedAt }) => deactivatedAt == null)
          .map((storeDepartment) => ({
            id: storeDepartment.id,
            imageURL: storeDepartment.imageURL,
            name: storeDepartment.name,
            storeID: storeDepartment.store.serialID || storeDepartment.store.id,
            storeName: storeDepartment.store.name || 'N/A',
            deactivatedAt: storeDepartment.deactivatedAt,
            parentDepartmentID: storeDepartment.parentDepartment
              ? storeDepartment.parentDepartment.id
              : undefined,
          })) || [],
        ['storeName', 'name'],
      ),
    [data],
  )

  return { departments, loading, error }
}

export function useTaxes(
  queryOptions?: Parameters<
    typeof useBackOfficeTaxManagementGetTaxRatesQuery
  >['0'],
): {
  error?: ApolloError
  loading: boolean
  taxRates: Array<GqlTaxRate>
} {
  const { data, error, loading } = useBackOfficeTaxManagementGetTaxRatesQuery({
    ...queryOptions,
    fetchPolicy: 'cache-first',
  })
  const taxRates = React.useMemo<Array<GqlTaxRate>>(
    () => sortBy(data?.taxRates || [], ['name']) as Array<GqlTaxRate>,
    [data],
  )
  return { taxRates, loading, error }
}

export function useItemModifiers({
  storeId,
  skip,
}: {
  storeId: string
  skip?: boolean
}): {
  itemModifiers: Array<ItemModifierDto>
  loading: boolean
  error: Error | undefined
} {
  const { data, loading, error } = useRestAPIGet<ItemModifierDto[]>({
    skip: skip ?? !storeId,
    endpoint: `/v1/item-modifiers?store_id=${storeId}`,
  })
  const itemModifiers = React.useMemo<Array<ItemModifierDto>>(
    () => sortBy(data || [], ['name']) as Array<ItemModifierDto>,
    [data],
  )
  return { itemModifiers, loading, error }
}

export function useVariableWeights({
  storeId,
  skip,
}: {
  storeId: string
  skip?: boolean
}): {
  variableWeights: Array<VariableWeightDto>
  loading: boolean
  error: Error | undefined
} {
  const { data, loading, error } = useRestAPIGet<VariableWeightDto[]>({
    skip: skip ?? !storeId,
    endpoint: `/v1/variable-weights?store_id=${storeId}`,
  })
  const variableWeights = React.useMemo<Array<VariableWeightDto>>(
    () => sortBy(data || [], ['name']) as Array<VariableWeightDto>,
    [data],
  )
  return { variableWeights, loading, error }
}

export function useStoreVendors({
  storeId,
  skip,
}: {
  storeId: string
  skip?: boolean
}): {
  storeVendors: Array<Vendor>
  loading: boolean
  error: Error | undefined
} {
  const { data, loading, error } = useRestAPIGet<{
    data: Vendor[]
  }>({
    endpoint: `/v1/store-vendors?limit=1000&filter.storeID=$eq:${storeId}`,
    skip: skip,
  })

  const storeVendors = React.useMemo<Array<Vendor>>(
    () => sortBy(data?.data || [], ['name']) as Array<Vendor>,
    [data],
  )
  return { storeVendors, loading, error }
}
