import { ChevronUpIcon } from '@vori/gourmet-icons'
import { Clickable, toRem } from '@vori/gourmet-components'
import React from 'react'
import styled from 'styled-components'

const StyledClickable = styled(Clickable)({
  bottom: toRem(10),
  position: 'fixed',
  right: toRem(10),
  zIndex: 10,
})

export function ScrollToTopButton(): JSX.Element | null {
  const [isVisible, setIsVisible] = React.useState<boolean>(
    window.scrollY >= 100,
  )

  const handleScroll = React.useCallback(() => {
    setIsVisible(window.scrollY >= 100)
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return isVisible ? (
    <StyledClickable
      leftIcon={<ChevronUpIcon />}
      noPadding
      onClick={() => {
        document.querySelector('html')?.scrollTo({ top: 0, behavior: 'smooth' })
      }}
      variant="primary"
    >
      Scroll to top
    </StyledClickable>
  ) : null
}
