import { GATED_FEATURE_IDs } from '@vori/dashboard-constants'
import { ENV_PROD } from '@vori/dashboard-env'

import { useCurrentUser } from './useCurrentUser'

type GatedFeaturesType = (typeof GATED_FEATURE_IDs)[number]

export function useFeatureConfig(featureID: GatedFeaturesType): boolean {
  const user = useCurrentUser()

  if (!GATED_FEATURE_IDs.includes(featureID)) {
    if (ENV_PROD) {
      return false
    }

    // Throw errors in dev only
    throw new Error(
      `Unable to fetch status for Feature "${featureID}". Make sure to add the ID to the GATED_FEATURES list.`,
    )
  }

  const enabledFeatures = user.data.canSeeFeatures?.enabledFeatures || []

  return Boolean(enabledFeatures?.includes(featureID))
}
