import { foundations } from '@vori/gourmet-tokens'
import React, { lazy, Suspense } from 'react'

import {
  AppFrame,
  AppHeader,
  FlexNext as Flex,
  Loader,
  TextNext as Text,
} from '@vori/gourmet-components'

import { ClockRewindIcon } from '@vori/gourmet-icons'

import {
  AppSidebar,
  DevUserGate,
  RetailUserGate,
  VendorUserGate,
} from '@vori/dashboard-components'

import { useCurrentUser } from '@vori/dashboard-hooks/useCurrentUser'
import { useIntegrations } from '@vori/dashboard-hooks/useIntegrations'
import { usePageContainerProps } from '@vori/dashboard-hooks/usePageContainerProps'
import { usePageHeaderActions } from '@vori/dashboard-hooks/usePageHeaderActions'
import { usePageTitle } from '@vori/dashboard-hooks/usePageTitle'
import { routes as retailRoutes } from '@vori/dashboard-routes/retail'
import { getTimeZone, toReadableEnum } from '@vori/dashboard-utils'

import { History } from 'history'
import { AuthRoutes } from './auth'

const DevRoutes = lazy(() => import('./dev'))
const RetailRoutes = lazy(() => import('./retail'))
const VendorRoutes = lazy(() => import('./vendor'))

type Props = {
  enableCourierInbox: boolean
  history: History<unknown>
}

function Routes({ enableCourierInbox, history }: Props): JSX.Element {
  useIntegrations(history)

  const user = useCurrentUser()
  const pageContainerProps = usePageContainerProps()
  const pageHeaderActions = usePageHeaderActions(enableCourierInbox)
  const pageTitle = usePageTitle()

  const isUsingStoreTimeZone = [
    retailRoutes.transactions.root.match(),
    retailRoutes.tills.root.match(),
  ].some(Boolean)

  return (
    <AppFrame
      containerProps={pageContainerProps}
      header={
        <AppHeader
          rightContent={(pageHeaderActions || []).concat([
            <Flex key="header-timeZone" centerY columnGap="space.025">
              <ClockRewindIcon />
              <Text style={{ color: foundations.color['color.base-white'] }}>
                {isUsingStoreTimeZone
                  ? user.metadata.timeZone
                  : getTimeZone().split('/').map(toReadableEnum).join('/')}
              </Text>
            </Flex>,
          ])}
          title={pageTitle}
        />
      }
      sidebar={<AppSidebar />}
    >
      {user.state.hasError ? (
        <Flex center flex={1} fullWidth>
          <Text variant="negative">
            There was an error while authenticating this user, please try again.
          </Text>
        </Flex>
      ) : user.state.isLoading && !user.state.isLoggedIn ? (
        <Flex center flex={1} fullWidth>
          <Loader size="large" />
        </Flex>
      ) : (
        <>
          <Suspense
            fallback={
              <Flex center flex={1} fullWidth>
                <Loader size="large" />
              </Flex>
            }
          >
            <AuthRoutes />
            <RetailUserGate>
              <RetailRoutes
                key={`selected-store-${user.metadata.selectedStoreID}`}
              />
            </RetailUserGate>
            <VendorUserGate>
              <VendorRoutes />
            </VendorUserGate>
            <DevUserGate>
              <DevRoutes />
            </DevUserGate>
          </Suspense>
        </>
      )}
    </AppFrame>
  )
}

export { Routes }
