import React from 'react'

export type HookOptions = {
  /**
   * A boolean flag to determine if the scroll lock is active.
   */
  isActive?: boolean
}

/**
 * Temporarily disables scrolling on the document body.
 */
export function useBodyScrollLock({ isActive }: HookOptions): void {
  React.useEffect(() => {
    if (!isActive) {
      return
    }

    const originalStyle = document.body.style.overflow
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [isActive])
}
