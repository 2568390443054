import React from 'react'
import { useCurrentUser } from '@vori/dashboard-hooks/useCurrentUser'

import { foundations } from '@vori/gourmet-tokens'

import {
  FlexNext as Flex,
  Loader,
  Select,
  TextNext as Text,
} from '@vori/gourmet-components'

import { Building02Icon } from '@vori/gourmet-icons'

import styled from 'styled-components'

const SelectContainer = styled(Flex)({
  '&&&& [data-gourmet-button]': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: foundations.color['color.base-white'],
    padding: 0,
  },

  [`${Text}`]: {
    color: foundations.color['color.base-white'],
  },
})

export function StoreSelector(): JSX.Element | null {
  const user = useCurrentUser()

  if (user.state.isLoadingEntities) {
    return (
      <SelectContainer centerY columnGap="space.025">
        <Loader size="tiny" variant="secondary" />
        <Text>Loading Stores...</Text>
      </SelectContainer>
    )
  }

  if (!user.entities.stores.length) {
    return null
  }

  if (user.entities.stores.length === 1) {
    return (
      <SelectContainer centerY columnGap="space.025">
        <Building02Icon />
        <Text>{user.entities.stores[0].name}</Text>
      </SelectContainer>
    )
  }

  return (
    <SelectContainer centerY columnGap="space.025">
      <Flex shrink={0}>
        <Building02Icon />
      </Flex>
      <Select
        fullWidth
        label="Select a Store"
        getLabelForTrigger={(_, { originalOptionSelected }) =>
          originalOptionSelected?.name || 'Your store'
        }
        options={user.entities.stores}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ serialID, id }) => serialID || id}
        getOriginalOption={(optionValue) =>
          user.entities.stores.find(
            ({ serialID, id }) =>
              serialID === optionValue || id === optionValue,
          ) || null
        }
        selectedOption={user.metadata.selectedStoreID || null}
        onChange={({ originalOptionSelected }) => {
          user.utils.selectStore(
            originalOptionSelected?.serialID ||
              originalOptionSelected?.id ||
              null,
            originalOptionSelected?.name || null,
          )
        }}
        popupProps={{
          size: 'small',
        }}
      />
    </SelectContainer>
  )
}
