import isEqual from 'lodash/isEqual'
import React from 'react'

import { PlusIcon } from '@vori/gourmet-icons'
import { useClientId, usePreviousValue } from '@vori/react-hooks'

import {
  addValuesToSet,
  addValuesToSetInState,
  mapSet,
  removeValuesFromSet,
  removeValuesFromSetInState,
  setToArray,
} from '@vori/utils-set'

import { Button } from '../ButtonNext'
import { Flex } from '../FlexNext'
import { Select } from '../Select'

import { parseInputValue } from '../CurrencyRangeInput'
import { useControlledState } from '../hooks'
import { composeRefs, inputValueAsString } from '../utils'

import {
  FilterMenuBarCoreProps,
  FilterMenuBarOption,
  FilterMenuBarOptionValue,
  FilterMenuBarProps,
} from './types'

import { FilterMenuBarChip } from './FilterMenuBarChip'

import {
  getDateRangeLabel,
  getMultiSelectLabel,
  getRangeInputLabel,
  getSelectLabel,
} from './utils'

const defaultProps: Partial<FilterMenuBarCoreProps> = {
  defaultFilterValues: {},
  defaultSelectedOptions: new Set(),
  withoutOptionSelect: false,
}

const FilterMenuBar = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<FilterMenuBarProps>
>(function FilterMenuBar(
  {
    defaultFilterValues,
    defaultSelectedOptions,
    onChangeFilters,
    options,
    onSelectedFilterChange,
    selectedOptions: controlledSelectedOptions,
    withoutOptionSelect,
    ...props
  }: FilterMenuBarProps,
  ref,
): JSX.Element {
  const [filterSelectID, resetFilterSelectID] = useClientId(
    'gourmet-filter-menu-select',
  )

  const [selectedOptions, setSelectedOptions] = useControlledState<Set<string>>(
    {
      componentName: 'FilterMenuBar',
      controlledValue: controlledSelectedOptions,
      defaultValue: withoutOptionSelect
        ? new Set(options.map(({ id }) => id))
        : defaultSelectedOptions || new Set(),
    },
  )

  const prevSelectedOptionsCount = usePreviousValue(selectedOptions.size)

  const [internalRef, setInternalRef] = React.useState<HTMLDivElement | null>(
    null,
  )

  const [currentFilters, setCurrentFilters] = React.useState<
    Record<string, FilterMenuBarOptionValue>
  >(defaultFilterValues || ({} as Record<string, FilterMenuBarOptionValue>))

  const prevCurrentFilters = usePreviousValue(currentFilters)

  const removeFilter = React.useCallback(
    (optionID) => {
      if (!withoutOptionSelect) {
        setSelectedOptions(removeValuesFromSetInState(optionID))
      }
      setCurrentFilters(
        (prevFilters) =>
          Object.fromEntries(
            Object.entries(prevFilters).filter(
              (filter) => filter[0] !== optionID,
            ),
          ) as Record<string, FilterMenuBarOptionValue>,
      )

      resetFilterSelectID()
    },
    [resetFilterSelectID, setSelectedOptions, withoutOptionSelect],
  )

  React.useEffect(() => {
    if (
      prevCurrentFilters !== undefined &&
      !isEqual(prevCurrentFilters, currentFilters)
    ) {
      onChangeFilters?.(currentFilters)
    }
  }, [
    currentFilters,
    onChangeFilters,
    onSelectedFilterChange,
    prevCurrentFilters,
  ])

  React.useLayoutEffect(() => {
    if (!internalRef) {
      return
    }

    if (
      prevSelectedOptionsCount !== undefined &&
      prevSelectedOptionsCount < selectedOptions.size
    ) {
      const $lastChipEl = Array.from(
        internalRef.querySelectorAll<HTMLButtonElement>(
          '[data-gourmet-filter-menubar-chip]',
        ),
      ).at(-1)

      if (!$lastChipEl) {
        return
      }

      $lastChipEl.dispatchEvent(
        new Event(
          ['select', 'multi-select', 'nested-multi-select'].includes(
            $lastChipEl.dataset.type || '',
          )
            ? 'mousedown'
            : 'click',
          {
            bubbles: true,
            cancelable: true,
            composed: true,
          },
        ),
      )
    }
  }, [internalRef, prevSelectedOptionsCount, selectedOptions.size])

  return (
    <Flex
      fullWidth
      wrap
      {...props}
      key={props.accessKey}
      data-gourmet-filter-menubar=""
      centerY
      gap="space.050"
      ref={composeRefs([ref, setInternalRef])}
    >
      {mapSet(selectedOptions, (selectedOptionID, index) => {
        const selectedOption = options.find(({ id }) => id === selectedOptionID)

        if (!selectedOption) {
          return null
        }

        switch (selectedOption.type) {
          case 'select': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              renderTrigger: (
                isOpen,
                { state, deselectOption },
                computedLabel,
              ) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  data-expanded={isOpen}
                  data-type={selectedOption.type}
                  data-value={state.selectedOption}
                  hasSelectedValue={state.hasSelectedOption}
                  isOpen={isOpen}
                  leftIcon={selectedOption.icon}
                  label={
                    state.selectedOption
                      ? getSelectLabel(computedLabel)
                      : selectedOption.label
                  }
                  onClickClose={() => {
                    deselectOption()
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (internals) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: internals.state.selectedOption,
                    id: selectedOption.id,
                    originalValue: internals.state.selectedOption,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'multi-select': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              footerActionsProps: { withSingleClearAction: true },
              renderTrigger: (
                isOpen,
                { state, deselectAll },
                computedLabel,
              ) => {
                return (
                  <FilterMenuBarChip
                    key={`filter-menu-bar-${index}`}
                    data-expanded={isOpen}
                    data-type={selectedOption.type}
                    data-value={setToArray(state.selectedOptions).join(',')}
                    hasSelectedValue={state.hasSelectedOptions}
                    isOpen={isOpen}
                    leftIcon={selectedOption.icon}
                    label={
                      state.hasSelectedOptions
                        ? getMultiSelectLabel(computedLabel)
                        : selectedOption.label
                    }
                    onClickClose={() => {
                      deselectAll()
                      removeFilter(selectedOption.id)
                    }}
                  />
                )
              },
              onChange: (internals) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: internals.state.selectedOptions,
                    id: selectedOption.id,
                    originalValue: internals.state.selectedOptions,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'nested-multi-select': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              footerActionsProps: { withSingleClearAction: true },
              renderTrigger: (
                isOpen,
                { state, deselectAll },
                computedLabel,
              ) => {
                return (
                  <FilterMenuBarChip
                    key={`filter-menu-bar-${index}`}
                    data-expanded={isOpen}
                    data-type={selectedOption.type}
                    data-value={setToArray(state.selectedOptions).join(',')}
                    hasSelectedValue={state.hasSelectedOptions}
                    isOpen={isOpen}
                    leftIcon={selectedOption.icon}
                    label={
                      state.hasSelectedOptions
                        ? getMultiSelectLabel(computedLabel)
                        : selectedOption.label
                    }
                    onClickClose={() => {
                      deselectAll()
                      removeFilter(selectedOption.id)
                    }}
                  />
                )
              },
              onChange: (internals) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: internals.state.selectedOptions,
                    id: selectedOption.id,
                    originalValue: internals.state.selectedOptions,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'date-range': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              withConfirmationControls: true,
              renderTrigger: (
                triggerProps,
                { deselectAll, isOpen, selectedDates, selectedTimes },
              ) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  {...triggerProps}
                  data-type={selectedOption.type}
                  leftIcon={selectedOption.icon}
                  isOpen={isOpen}
                  hasSelectedValue={selectedDates.length > 0}
                  label={
                    selectedDates.length > 0
                      ? getDateRangeLabel(
                          selectedDates,
                          Boolean(selectedTimes.length),
                        )
                      : selectedOption.label
                  }
                  onClickClose={() => {
                    deselectAll()
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (event) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: event.target.value
                      .split(',')
                      .map((value) => (value ? new Date(value) : null))
                      .filter(Boolean) as Array<Date>,
                    id: selectedOption.id,
                    originalValue: event.target.value,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'date-select': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              withConfirmationControls: true,
              renderTrigger: (
                triggerProps,
                { deselectAll, isOpen, selectedDates, selectedTimes },
              ) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  {...triggerProps}
                  data-type={selectedOption.type}
                  leftIcon={selectedOption.icon}
                  isOpen={isOpen}
                  hasSelectedValue={selectedDates.length > 0}
                  label={
                    selectedDates.length > 0
                      ? getDateRangeLabel(
                          selectedDates,
                          Boolean(selectedTimes.length),
                        )
                      : selectedOption.label
                  }
                  onClickClose={() => {
                    deselectAll()
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (event) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: new Date(event.target.value),
                    id: selectedOption.id,
                    originalValue: event.target.value,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'currency-range': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              type: 'currency',
              rangeInputProps: {
                currencyInputProps: { withSymbol: true },
              },
              renderTrigger: (triggerProps, value, isOpen) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  {...triggerProps}
                  data-type={selectedOption.type}
                  hasSelectedValue={Boolean(value)}
                  isOpen={isOpen}
                  label={
                    getRangeInputLabel(inputValueAsString(value) || null) ||
                    selectedOption.label
                  }
                  leftIcon={selectedOption.icon}
                  onClickClose={() => {
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (event) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: event.target.value
                      .split(',')
                      .map((value) => (value ? parseInputValue(value) : null)),
                    id: selectedOption.id,
                    originalValue: event.target.value,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'number-range': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              type: 'number',
              renderTrigger: (triggerProps, value, isOpen) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  {...triggerProps}
                  data-type={selectedOption.type}
                  hasSelectedValue={Boolean(value)}
                  isOpen={isOpen}
                  label={
                    getRangeInputLabel(inputValueAsString(value) || null) ||
                    selectedOption.label
                  }
                  leftIcon={selectedOption.icon}
                  onClickClose={() => {
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (event) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: event.target.value
                      .split(',')
                      .map((value) =>
                        value ? Number.parseFloat(value) : null,
                      ),
                    id: selectedOption.id,
                    originalValue: event.target.value,
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          case 'text': {
            return selectedOption.renderFilter({
              key: selectedOption.id,
              label: selectedOption.label,
              icon: selectedOption.icon,
              renderTrigger: (triggerProps, value, isOpen) => (
                <FilterMenuBarChip
                  key={`filter-menu-bar-${index}`}
                  {...triggerProps}
                  data-type={selectedOption.type}
                  leftIcon={selectedOption.icon}
                  isOpen={isOpen}
                  hasSelectedValue={!!value?.[0]}
                  label={value?.[0] ? value?.[0] : selectedOption.label}
                  onClickClose={() => {
                    removeFilter(selectedOption.id)
                  }}
                />
              ),
              onChange: (value, type) => {
                setCurrentFilters((prevFilters) => ({
                  ...prevFilters,
                  [selectedOption.id]: {
                    formattedValue: [value, type],
                    id: selectedOption.id,
                    originalValue: [value, type],
                    type: selectedOption.type,
                  },
                }))
              },
            })
          }

          default: {
            return null
          }
        }
      })}

      {(!withoutOptionSelect || selectedOptions.size !== options.length) && (
        <Select
          key={filterSelectID}
          label="Add Filter"
          options={Array.from<FilterMenuBarOption>(options.values()).filter(
            ({ id }) => !selectedOptions.has(id),
          )}
          getOptionMenuItemProps={({ icon }) => ({ icon })}
          getOptionKey={({ id }) => id}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ id }) => id}
          getOriginalOption={(optionValue) =>
            optionValue
              ? options.find(({ id }) => optionValue === id) || null
              : null
          }
          renderTrigger={(isOpen, { state }) => (
            <Button
              data-expanded={isOpen}
              data-value={state.selectedOption}
              rightIcon={<PlusIcon />}
              sizing="small"
            >
              Add Filter
            </Button>
          )}
          onChange={(internals) => {
            const { originalOptionAdded, originalOptionRemoved } = internals

            if (originalOptionRemoved) {
              onSelectedFilterChange?.(
                removeValuesFromSet(selectedOptions, originalOptionRemoved.id),
              )
              setSelectedOptions(
                removeValuesFromSetInState(originalOptionRemoved.id),
              )
            } else if (originalOptionAdded) {
              const newValues = addValuesToSet(
                selectedOptions,
                originalOptionAdded.id,
              )
              onSelectedFilterChange?.(newValues)
              setSelectedOptions(addValuesToSetInState(originalOptionAdded.id))
            }
          }}
        />
      )}
    </Flex>
  )
})

FilterMenuBar.displayName = 'FilterMenuBar'
FilterMenuBar.defaultProps = defaultProps

export { FilterMenuBar, defaultProps as filterMenuBarDefaultProps }
