import React, { useMemo } from 'react'
import { useLocation } from 'react-router'

import styled from 'styled-components'
import { Inbox } from '@trycourier/react-inbox'

import {
  NavButtonNext as NavButton,
  foundation,
} from '@vori/gourmet-components'

import { Analytics, showIntercom } from '@vori/dashboard-utils'
import { AppHeaderProps } from '@vori/gourmet-components'
import { Bell01Icon } from '@vori/gourmet-icons'
import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { ENV_PROD } from '@vori/dashboard-env'

const Link = styled(NavButton)({
  color: `${foundation.colors.pureWhite} !important`,

  ':hover, :active, :focus, &.active': {
    backgroundColor: 'transparent !important',
    color: `${foundation.colors.pureWhite} !important`,
    textDecoration: 'underline',
  },

  ':visited': {
    color: `${foundation.colors.pureWhite} !important`,
  },
})

function usePageHeaderActions(
  enableCourierInbox: boolean,
): AppHeaderProps['rightContent'] {
  const location = useLocation()

  const actions = useMemo<AppHeaderProps['rightContent']>(() => {
    if (
      [
        authRoutes.login.root,
        authRoutes.passwordRecovery.root,
        authRoutes.vendorInvitation.root,
      ].some((routeUtil) => routeUtil.match())
    ) {
      return []
    }

    return [
      ...(enableCourierInbox
        ? [
            <Inbox
              key="headerAction-notificationInbox"
              renderIcon={() => {
                return <Bell01Icon size="md" />
              }}
            />,
          ]
        : []),
      <Link
        key="headerAction-logout"
        asClickable
        noPadding
        size="small"
        to={authRoutes.logout.root.path}
      >
        Logout
      </Link>,
      <Link
        key="headerAction-getSupport"
        asClickable
        noPadding
        onClick={(event) => {
          event.preventDefault()

          if (ENV_PROD) {
            Analytics.track(Analytics.EventNames.VENDOR_NAV_GET_SUPPORT_CLICKED)
            showIntercom()
          }
        }}
        size="small"
        to="/help"
      >
        Get Support
      </Link>,
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, enableCourierInbox])

  return actions
}

export { usePageHeaderActions }
