const INTERCOM_APP_ID = 'i1sp17e9'

function initIntercom(data: Intercom_.IntercomSettings): void {
  window?.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    ...data,
  })
}

function shutdownIntercom(): void {
  window?.Intercom('shutdown')
}

function showIntercom(): void {
  window?.Intercom('show')
}

function hideIntercom(): void {
  window?.Intercom('hide')
}

function startIntercomConversation(prePopulateMessage?: string): void {
  window?.Intercom('showNewMessage', prePopulateMessage)
}

export {
  hideIntercom,
  initIntercom,
  showIntercom,
  shutdownIntercom,
  startIntercomConversation,
}
