export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24

export type DateLike = string | number | Date

export function isValidDate(value?: DateLike): boolean {
  if (!value) {
    return false
  }

  const date = new Date(value)

  if (date instanceof Date && !Number.isNaN(date.valueOf())) {
    return true
  }

  return false
}

export function isPastDate(value?: DateLike): boolean {
  if (!value || !isValidDate(value)) {
    return false
  }

  const dueDate = new Date(value)
  const currentDate = new Date()

  return dueDate.getTime() < currentDate.getTime()
}

export function isFutureDate(value?: DateLike): boolean {
  if (!value || !isValidDate(value)) {
    return false
  }

  const dueDate = new Date(value)
  const currentDate = new Date()

  return dueDate.getTime() > currentDate.getTime()
}

export function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getStartDate(date: Date): Date {
  return new Date(date.setHours(0, 0, 0, 0))
}

export function getEndDate(date: Date): Date {
  return new Date(date.setHours(23, 59, 59, 999))
}

export function getDifferenceInDays(
  date: DateLike,
  baselineDate?: DateLike,
): number | -1 {
  if (!date || !isValidDate(date)) {
    return -1
  }

  if (baselineDate && !isValidDate(baselineDate)) {
    return -1
  }

  const dateA = new Date(date).getTime()

  const dateB = (
    baselineDate ? new Date(baselineDate) : getStartDate(new Date())
  ).getTime()

  return dateA < dateB ? -1 : Math.round((dateA - dateB) / DAY_IN_MILLISECONDS)
}
