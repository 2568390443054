import { Popover, positionDefault } from '@reach/popover'
import { Scales01Icon } from '@vori/gourmet-icons'
import { useClientId, usePreviousValue } from '@vori/react-hooks'
import React from 'react'

import { useControlledState, useOnClickOutside, useOpenState } from '../hooks'

import {
  composeEventHandlers,
  composeRefs,
  createOnChangeEvent,
  getInputAriaProps,
  inputValueAsString,
  toRem,
} from '../utils'

import { Button, ButtonProps } from '../ButtonNext'
import { Card } from '../CardNext'
import { CurrencyRangeInput, parseInputValue } from '../CurrencyRangeInput'
import { Divider } from '../Divider'
import { Flex } from '../FlexNext'
import { FocusTrap } from '../FocusTrap'
import { NumberRangeInput, validateNumberRangeValue } from '../NumberRangeInput'
import { RangeInput } from '../RangeInput'
import { Spacer } from '../SpacerNext'
import { Text } from '../TextNext'

import { RangeComboboxProps } from './types'

const RangeCombobox = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RangeComboboxProps>
>(function RangeCombobox(
  {
    descriptionID,
    errorID,
    icon,
    id: controlledID,
    labelID,
    onChange,
    onClose,
    onOpen,
    rangeInputProps,
    renderLabel,
    renderTrigger,
    type,
  }: React.PropsWithChildren<RangeComboboxProps>,
  ref,
): JSX.Element {
  const parseValue =
    type === 'number'
      ? rangeInputProps?.parseInputValue
      : type === 'currency'
        ? rangeInputProps?.parseInputValue || parseInputValue
        : undefined

  const tempValueRef =
    React.useRef<React.InputHTMLAttributes<HTMLInputElement>['value']>(
      undefined,
    )

  const [value, setValue] = React.useState<
    React.InputHTMLAttributes<HTMLInputElement>['value']
  >(rangeInputProps?.value)

  const prevCurrentValue = usePreviousValue(value)

  const [resetID, refreshResetID] = useClientId()
  const [defaultID] = useClientId('gourmet-range-combobox')

  const [internalRef, setInternalRef] = React.useState<HTMLInputElement | null>(
    null,
  )

  const [inputID] = useControlledState({
    componentName: 'RangeCombobox (id)',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [error, setError] = React.useState(
    rangeInputProps?.value
      ? validateNumberRangeValue(rangeInputProps.value, parseValue)[0]
      : null,
  )

  const popover = useOpenState({
    onClose,
    onOpen,
  })

  const [targetRef, setTargetRef] = React.useState<HTMLButtonElement | null>(
    null,
  )

  const [popoverRef, setPopoverRef] = React.useState<HTMLDivElement | null>(
    null,
  )

  const close = React.useCallback<() => void>(() => {
    popover.close()

    if (targetRef) {
      targetRef.focus()
    }
  }, [popover, targetRef])

  const closeAndResetIfNeeded = React.useCallback<() => void>(() => {
    tempValueRef.current = undefined

    if (!value) {
      refreshResetID()
    }

    close()
  }, [close, refreshResetID, value])

  const handleComboboxKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLButtonElement>
  >(
    (event) => {
      switch (event.key) {
        case 'ArrowDown': {
          popover.open()
          break
        }

        case 'Escape': {
          closeAndResetIfNeeded()
          break
        }

        default: {
          break
        }
      }
    },
    [popover, closeAndResetIfNeeded],
  )

  const handleDialogKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLDivElement>
  >(
    (event) => {
      switch (event.key) {
        case 'Escape': {
          closeAndResetIfNeeded()
          break
        }

        default: {
          break
        }
      }
    },
    [closeAndResetIfNeeded],
  )

  const rangeInputOnChange = React.useMemo(
    () =>
      composeEventHandlers(rangeInputProps?.onChange, (event) => {
        tempValueRef.current = event.target.value
        setError(validateNumberRangeValue(event.target.value, parseValue)[0])
      }),
    [parseValue, rangeInputProps?.onChange],
  )

  const triggerProps = React.useMemo<ButtonProps>(
    () => ({
      ...(value && { 'data-value': value }),
      'aria-autocomplete': 'none',
      'aria-controls': `${inputID}-gourmet-range-combobox-dialog`,
      'aria-expanded': popover.isOpen,
      'aria-haspopup': 'dialog',
      disabled: rangeInputProps?.disabled,
      leftIcon: icon !== undefined ? icon : <Scales01Icon />,
      onClick: (event) => {
        popover.toggle()
        event.currentTarget.focus()
        event.preventDefault()
      },
      onKeyDown: handleComboboxKeyDown,
      ref: setTargetRef,
      role: 'combobox',
      tabIndex: 0,
    }),
    [
      value,
      inputID,
      popover,
      rangeInputProps?.disabled,
      icon,
      handleComboboxKeyDown,
    ],
  )

  useOnClickOutside(
    [targetRef, popoverRef],
    closeAndResetIfNeeded,
    !popover.isOpen,
  )

  React.useEffect(() => {
    if (!internalRef) {
      return
    }

    if (prevCurrentValue !== value) {
      internalRef.value = inputValueAsString(value)
      onChange?.(createOnChangeEvent(internalRef))
    }
  }, [internalRef, onChange, prevCurrentValue, value])

  return (
    <Flex data-gourmet-range-combobox="">
      {renderTrigger ? (
        renderTrigger(triggerProps, value, popover.isOpen)
      ) : (
        <Button
          {...triggerProps}
          data-gourmet-range-combobox-trigger=""
          withLeftAlignedText
        >
          {renderLabel ? renderLabel(value) : value ? value : 'Set range'}
        </Button>
      )}

      <Popover
        aria-label={'Enter a range value'}
        aria-modal="true"
        id={`${inputID}-gourmet-range-combobox-dialog`}
        onKeyDown={handleDialogKeyDown}
        position={positionDefault}
        role="dialog"
        hidden={!popover.isOpen}
        style={{ zIndex: 1001 }}
        targetRef={{ current: targetRef }}
      >
        <Spacer size="space.050" />
        <FocusTrap autoFocus disabled={!popover.isOpen}>
          {(focusTrapRef, focusTrapProps) => (
            <Card
              fullWidth
              gap="space.075"
              ref={composeRefs([setPopoverRef, (el) => focusTrapRef(el)])}
              style={{ maxWidth: toRem(300) }}
              {...focusTrapProps}
            >
              {type === 'default' ? (
                <RangeInput
                  key={resetID}
                  {...getInputAriaProps({ descriptionID, errorID, labelID })}
                  {...rangeInputProps}
                  onChange={rangeInputOnChange}
                  ref={composeRefs([ref, setInternalRef])}
                />
              ) : type === 'currency' ? (
                <CurrencyRangeInput
                  key={resetID}
                  {...getInputAriaProps({ descriptionID, errorID, labelID })}
                  {...rangeInputProps}
                  onChange={rangeInputOnChange}
                  ref={composeRefs([ref, setInternalRef])}
                />
              ) : type === 'number' ? (
                <NumberRangeInput
                  key={resetID}
                  {...getInputAriaProps({ descriptionID, errorID, labelID })}
                  {...rangeInputProps}
                  onChange={rangeInputOnChange}
                  ref={composeRefs([ref, setInternalRef])}
                />
              ) : null}
              {Boolean(error) && (
                <Text size="text-xs" variant="negative">
                  {error}
                </Text>
              )}
              <Flex columnOffset="space.125">
                <Divider />
              </Flex>
              <Flex centerY gap="space.075" fullWidth justifyContent="flex-end">
                <Button onClick={closeAndResetIfNeeded} size="small">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setValue(tempValueRef.current)
                    tempValueRef.current = undefined
                    close()
                  }}
                  disabled={Boolean(error)}
                  size="small"
                  variant="secondary"
                >
                  Apply
                </Button>
              </Flex>
            </Card>
          )}
        </FocusTrap>
      </Popover>
    </Flex>
  )
})

RangeCombobox.displayName = 'RangeCombobox'
RangeCombobox.defaultProps = {}

export { RangeCombobox }
